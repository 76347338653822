// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Server'

// Dependencies
import Vue from 'vue'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Mixin Extend
const MixinFetch = Vue.extend({
	methods: {
		_callEventOnFailedResponse: function(status: number, path: string) {
			if (status === Server.Response.StatusCodes.NULL_RESPONSE) {
				DevelopmentTools.printWarn('[MixinFetch] onServerFailedResponse: Status NULL del Servidor a petición:', path)
				if ('onServerFailedResponse' in this) {
					const self: any = this
					self.onServerFailedResponse(path)
				}
			}
		},

		_callEventOnCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, error: Error) {
			DevelopmentTools.printWarn('[MixinFetch] onServerCaughtFetchException:', path, resolution, status, error)
			if ('onServerCaughtFetchException' in this) {
				const self: any = this
				self.onServerCaughtFetchException(path, resolution, status, error)
			}
		},

		_handleExceptions: async function(status: number, path: string, error: Error) {
			// Manejo de posibles excepciones según el status de la petición original.
			this._callEventOnCaughtFetchException(path, Server.Fetching.Resolutions.NONE, status, error)
		},

		_onFetchCaughtException: async function(action: string, path: string, error: any) {
			this._printCatchException(action, path, error)
			await this._handleExceptions(error.status || null, path, error)
			return { status: error.response.status, data: error.response.data }
		},

		_printCatchException: function(action: string, path: string, err: Error) {
			DevelopmentTools.printWarn(`[MixinFetch] Excepción ocurrida en petición ${action}:`, path)
			DevelopmentTools.printWarn('[MixinFetch]', err)
		},

		fetchURL: async function(method: Server.Fetching.Method, url: string, payload: any = null, headers: any = null) {
			// Propiedades obligatorias que deben estar presentes en la data.
			const axiosHelper = new AxiosHelper
			DevelopmentTools.printWarn('[MixinFetch]:fetchURL() -> URL:', url)

			if (method === Server.Fetching.Method.GET) {
				try {
					const response = await axiosHelper.get(url, { headers, params: payload })
					this._callEventOnFailedResponse(response.status, url)
					return { status: response.status, data: response.data }
				}
				catch (err) {
					return this._onFetchCaughtException(method, url, err)
				}
			}
			else if (method === Server.Fetching.Method.PATCH) {
				try {
					const response = await axiosHelper.patch(url, { data: payload, headers })
					this._callEventOnFailedResponse(response.status, url)
					return { status: response.status, data: response.data }
				}
				catch (err) {
					return this._onFetchCaughtException(method, url, err)
				}
			}
			else if (method === Server.Fetching.Method.POST) {
				try {
					const response = await axiosHelper.post(url, { data: payload, headers })
					this._callEventOnFailedResponse(response.status, url)
					return { status: response.status, data: response.data }
				}
				catch (err) {
					return this._onFetchCaughtException(method, url, err)
				}
			}
		}
	}
})

// Exports
export default MixinFetch
export type MixinFetchRef = InstanceType<typeof MixinFetch>