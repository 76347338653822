// Classes
import { TextTools } from '@/Classes/Static/TextTools'

// Constants
import { AppValues } from '@/Constants/AppValues'

function _getDayOfTheYear(date: Date) {
	const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334]
	const mn = date.getMonth()
	const dn = date.getDate()
	let dayOfYear = dayCount[mn] + dn
	if (mn > 1 && _isLeapYear(date)) dayOfYear++
	return dayOfYear
}

function _isLeapYear(date: Date) {
	const year = date.getFullYear()
	if ((year & 3) != 0) return false
	return ((year % 100) != 0 || (year % 400) == 0)
}

export namespace PrimitiveTools {
	export class Arrays {
		static getFromStackByProperty<T, S>(arr: Array<T>, stack: Array<S>, property: string) {
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(arr) && !PrimitiveTools.Arrays.isInvalidOrEmpty(stack)) {
				const aux: any = []
				for (const e of stack) aux.push(arr.find((x: any) => x[property] === e))
				return aux
			}
		}

		static getLastElement(arr: Array<any>) {
			return arr[arr.length - 1]
		}

		static isInvalidOrEmpty(arr: Array<any>) {
			if (Array.isArray(arr)) {
				if (arr.length === 0) return true
				for (const v of arr) if (!v) return true
				return false
			}
			return true
		}

		static map<T>(arr: Array<T>, onTrueCallback: (item: T) => void, onFalse: () => any) {
			if (Array.isArray(arr) && arr.length > 0) {
				return arr.map(onTrueCallback)
			}
			return onFalse()
		}
	}

	export class Booleans {
		static isValid(b: boolean) {
			return typeof b === 'boolean'
		}

		static toBoolean(b: boolean | string | number) {
			if (typeof b === 'boolean') return b === true ? true : false
			if (typeof b === 'string') return b === 'true' ? true : false
			if (typeof b === 'number') return b === 1 ? true : false
			return false
		}
	}

	export class Dates {
		static getLatestCorrelativeDays(asDateOrString: 'String' | 'Date', numDays: number, date = new Date) {
			const DAY_IN_MS = 86400 * 1000
			const dates = []
			for (let i = 0; i < numDays; i++) {
				const curMS = date.getTime()
				date.setTime(curMS - (i === 0 ? 0 : DAY_IN_MS));
				(asDateOrString === 'Date') ? dates.push(date) : dates.push(PrimitiveTools.Dates.toDateString(date))
			}
			return dates
		}

		static getLatestCorrelativeMonths(asDateOrString: 'String' | 'Date', numMonths: number, date = new Date) {
			const dates = []
			for (let i = 0; i < numMonths; i++) {
				if (i > 0) date.setDate(0);
				(asDateOrString === 'Date') ? dates.push(date) : dates.push(PrimitiveTools.Dates.toDateString(date))
			}
			return dates
		}

		static getNumDaysBetween(date1: (string | Date), date2: (string | Date) = new Date()) {
			let _date1: Date
			let _date2: Date
			if (date1 !== undefined) {
				if (typeof date1 === 'string') {
					const sDate = date1.split('-')
					_date1 = new Date(parseInt(sDate[2]), parseInt(sDate[1]) - 1, parseInt(sDate[0]))
				}
				else _date1 = date1
			}
			if (date2 !== undefined) {
				if (typeof date2 === 'string') {
					const sDate = date2.split('-')
					_date2 = new Date(parseInt(sDate[2]), parseInt(sDate[1]) - 1, parseInt(sDate[0]))
				}
				else _date2 = date2
			}
			if (_date1 === undefined && _date2 === undefined) return null
			return _getDayOfTheYear(_date2) - _getDayOfTheYear(_date1)
		}

		static isValid(d: any) {
			return d instanceof Date && !isNaN(<any> d)
		}

		static isValidDateString(date: string) {
			const isValidDateHourString = /^\d\d\-\d\d\-\d\d\d\d\s\d\d:\d\d$/.test(date)
			const isValidDateString = /^\d\d\-\d\d\-\d\d\d\d$/.test(date)
			return isValidDateHourString || isValidDateString
		}

		static parseDateString(date: string, includeSeconds?: boolean, swapYearAndDays?: boolean) {
			// Convertir a tipo Date, el string de la Fecha.
			const asDate = new Date(date)
			if (!asDate) return null
			
			// Obtener los elementos de las Fechas.
			const days = asDate.getDate().toString().padStart(2, '0')
			const months = (asDate.getMonth() + 1).toString().padStart(2, '0')
			const year = asDate.getFullYear()
			
			// Segun el tercer parametro opcional, cambiar el orden de los días y año.
			const _date = swapYearAndDays ? `${ year }-${ months }-${ days }` : `${ days }-${ months }-${ year }`
			
			// Obtener los elementos de las Horas.
			const hours = asDate.getHours().toString().padStart(2, '0')
			const minutes = asDate.getMinutes().toString().padStart(2, '0')
			let _time = `${hours}:${minutes}`
			
			// Los Segundos son opcionales por defecto.
			if (includeSeconds) {
				const seconds = asDate.getSeconds().toString().padStart(2, '0')
				_time += `:${seconds}`
			}
			
			// Retornar objeto co ambas partes de una Fecha.
			return { date: _date, time: _time }
		}

		static reverseDate(sDate: string, separator = '-') {
			if (!sDate) return null
			const split = sDate.split(separator)
			return `${ split[2] }-${ split[1] }-${ split[0] }`
		}

		static toDateString(date: Date) {
			const day   = date.getDate().toString().padStart(2, '0')
			const month = (date.getMonth() + 1).toString().padStart(2, '0')
			const year  = date.getFullYear().toString()
			return `${ day }-${ month }-${ year }`.replace(/^\-/, '').replace(/\-$/, '')
		}
	}

	export class Objects {
		static createArrayPropertiesFromStack<T>(arr: Array<T>) {
			const obj: any = {}
			for (const e of arr) obj[e] = []
			return obj
		}

		static newModuleStructure(index: number, selectable: boolean, title: string, iconName: string) {
			return {
				_params: { index, selectable, visible: true },
				// colors: [color1, color2],
				icon: iconName,
				title
			}
		}
	}

	export class Strings {
		static isInvalidOrEmpty(str: string) {
			return str === null || str === undefined || str === ''
		}

		static isValidEmail(email: string) {
			return /^[a-z0-9]+(.+)@[a-z0-9]+\.[a-z0-9]+$/i.test(email)
		}

		static toLimitedLength(name: string, width = 100, font = '') {
			let formatted = ''
			for (let c of name) {
				if (TextTools.getWidth(formatted, font) < width) {
					formatted += c
				}
				else {
					formatted += '...'
					break
				}
			}
			return formatted
		}

		static stringify(val: any, onFalse: string = AppValues.Strings.DEFAULT_EMPTY_STRING) {
			if (val === undefined || val === null || (typeof val === 'string' && val.trim() === '')) {
				return onFalse
			}
			return val.toString()
		}
	}
}